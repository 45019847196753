import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';


i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'ru',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            ru: {
                translation: {
                    // here we will place our translations...
                    header: {
                        part1: 'Продукция',
                        part2: 'Отзывы',
                    },
                    main: {

                        part1: 'Лучшие условия по поставке импортного оборудования',
                        part2: 'экспорт и импорт промышленного оборудования',
                        part3: 'ЛАЗЕРНОЕ И ПЛАЗМЕННОЕ ОБОРУДОВАНИЕ',
                        part4: 'ПРОМЫШЛЕННОЕ ОБОРУДОВАНИЕ',
                        part5: 'ГИДРАВЛИЧЕСКОЕ И ПНЕВМАТИЧЕСКОЕ ОБОРУДОВАНИЕ',
                        part6: 'НАСОСНОЕ ОБОРУДОВАНИЕ',
                        part7: 'ПРОИЗВОДСТВЕННЫЕ ЛИНИИ',
                        part8: 'ЛАБОРАТОРНОЕ ОБОРУДОВАНИЕ',
                        part9: 'ФИЛЬТРОВАЛЬНОЕ ОБОРУДОВАНИЕ',
                        part10: 'КОМПЛЕКТУЮЩИЕ',

                    }
                    ,
                    delivery: {
                        part1: 'Осуществляем поставку промышленного оборудования для любой отрасли промышленности',
                        part2: 'Оборудование для дезинфекции',
                        part3: 'Промышленные станки',
                        part4: 'фильтровальное оборудование',
                        part5: 'насосное оборудование',
                        part6: 'гидравлика',
                        part7: 'приводная техника',
                        part8: 'различный кип',
                        part9: 'электрооборудование',
                        part10: 'Оборудования для горнодобывающей промышленности',
                        part11: 'Погрузочный ковш',
                        part12: 'Погрузочный ковш',
                        part13: 'Опорно-поворотные устройства',
                        part14: 'Компенсатор резиновый',
                    },
                    guarantee: {

                        part1: 'РАБОТАЯ С НАМИ, ВЫ ПОЛУЧИТЕ',
                        part2: 'КОРОТКИЙ СРОК ПОСТАВКИ ДО ВАШЕГО СКЛАДА',
                        part3: 'СОКРАЩЕНИЕ ЗАКУПОЧНЫХ ИЗДЕРЖЕК',
                        part4: 'ГАРАНТИЮ КАЧЕСТВА ОТ ПРОИЗВОДИТЕЛЯ'
                    },
                    search: {
                        part1: 'НЕ НАШЛИ ТОГО, ЧТО ИСКАЛИ?',
                        part2: 'ОСТАВЬТЕ ЗАЯВКУ И МЫ СВЯЖЕМСЯ В УДОБНОЕ ДЛЯ ВАС ВРЕМЯ',
                        part3: 'написать нам'
                    },
                    footer: {
                        part1: 'мкр.Городок Cтроителей, дом 9/2'
                    }

                }
            }

            ,

            en: {
                translation: {
                    // here we will place our translations...
                    header: {
                        part1: 'Products',
                        part2: 'Reviews',
                    },
                    main: {

                        part1: 'The best conditions for the supply of imported equipment',
                        part2: 'export and import of industrial equipment',
                        part3: 'LASER AND PLASMA EQUIPMENT',
                        part4: 'industrial equipment',
                        part5: 'HYDRAULIC AND PNEUMATIC EQUIPMENT',
                        part6: 'PUMP EQUIPMENT',
                        part7: 'PRODUCTION LINES',
                        part8: 'laboratory equipment',
                        part9: 'FILTERING EQUIPMENT',
                        part10: 'ACCESSORIES',
                    },
                    delivery: {
                        part1: 'We supply industrial equipment for any industry',
                        part2: 'Disinfection equipment',
                        part3: 'Industrial machines',
                        part4: 'filtration equipment',
                        part5: 'pump equipment',
                        part6: 'hydraulics',
                        part7: 'drive technology',
                        part8: 'various kip',
                        part9: 'electrical equipment',
                        part10: 'Equipment for the mining industry',
                        part11: 'loading bucket',
                        part12: 'loading bucket',
                        part13: 'Slewing devices',
                        part14: 'Rubber compensator',

                    },

                    guarantee: {
                        part1: 'WORKING WITH US, YOU WILL RECEIVE',
                        part2: 'SHORT DELIVERY TIME TO YOUR WAREHOUSE',
                        part3: 'REDUCED PROCUREMENT COSTS',
                        part4: 'QUALITY GUARANTEE FROM THE MANUFACTURER',
                    },
                    search: {
                        part1: 'DID NOT FIND WHAT YOU WERE LOOKING FOR?',
                        part2: 'LEAVE A REQUEST AND WE WILL CONTACT AT A TIME CONVENIENT FOR YOU',
                        part3: 'Write to us'
                    },
                    footer: {
                        part1: 'district Gorodok Stoiteley 9/2'
                    }
                }
            }
        }
    });

export default i18n;
