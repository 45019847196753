import React from 'react';
import  './Footer.css'

import logo from '../../images/115.png'
import {useTranslation} from "react-i18next";


const Footer = () => {
    const { t } = useTranslation()
    return (
        <div className="footer">
            <div className="footer-mask">

                    <div className="contacts">
                        <div className="tel">
                            <img
                                src={logo}
                                alt=""
                            />
                             <a>office@altynkur.kg</a>
                            <h3>+996 778 128 698</h3>
                            {/*<a>мкр. Городок Cтроителей, дом 9/2</a>*/}
                            <a>{t('footer.part1')}</a>

                        </div>
                    </div>
            </div>
        </div>


    );
};

export default Footer;
