import React from 'react';
import './Search.css'
import Container from "@mui/material/Container";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {useTranslation} from "react-i18next";

const Search = () => {
    const { t } = useTranslation()
    return (
        <div className="search">
          <div className="search_mask">
            <Container>
              {/*<h2>НЕ НАШЛИ ТОГО, ЧТО ИСКАЛИ?</h2>*/}
              <h2>{t('search.part1')}</h2>
                {/*<p>ОСТАВЬТЕ ЗАЯВКУ И МЫ СВЯЖЕМСЯ В УДОБНОЕ ДЛЯ ВАС ВРЕМЯ</p>*/}
                <p>{t('search.part2')}</p>

                <div className="search_button">
                    <Button variant="outlined" href="https://wa.me/+996 778 128 698" target="_blank">{t('search.part3')}</Button>
                </div>
            </Container>
          </div>
        </div>
    );
};

export default Search;
