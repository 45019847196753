import React from 'react';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import VerifiedIcon from '@mui/icons-material/Verified';
import './Guarantee.css'
import {useTranslation} from "react-i18next";

const Guarantee = () => {
    const { t } = useTranslation()
    return (
        <div className="guarantee">
          <div>
            {/*<h2>РАБОТАЯ С НАМИ, ВЫ ПОЛУЧИТЕ</h2>*/}
              <h2>{t('guarantee.part1')}</h2>
          </div>
          <div className="guarantee_box">
              <div className="guarantee_icons_box">
                  <div className="guarantee_icons">
                      <LocalShippingIcon color="primary" sx={{ fontSize: 150 }}/>
                  </div>
                  {/*<h3>КОРОТКИЙ СРОК ПОСТАВКИ ДО ВАШЕГО СКЛАДА</h3>*/}
                  <h3>{t('guarantee.part2')}</h3>
              </div>
              <div className="guarantee_icons_box">
                  <div className="guarantee_icons">
                      <AccountBalanceWalletIcon color="primary" sx={{ fontSize: 150 }}/>
                  </div>
                  {/*<h3>СОКРАЩЕНИЕ ЗАКУПОЧНЫХ ИЗДЕРЖЕК</h3>*/}
                  <h3>{t('guarantee.part3')}</h3>
              </div>
              <div className="guarantee_icons_box">
                  <div className="guarantee_icons">
                      <VerifiedIcon color="primary" sx={{ fontSize: 150 }}/>
                  </div>
                  {/*<h3>ГАРАНТИЮ КАЧЕСТВА ОТ ПРОИЗВОДИТЕЛЯ</h3>*/}
                  <h3>{t('guarantee.part4')}</h3>
              </div>

          </div>

        </div>
    );
};

export default Guarantee;
