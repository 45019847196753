import React from 'react';
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Main from "../components/Main/Main";
import Delivery from "../components/Delivery/Delivery";
import Guarantee from "../components/Guarantee/Guarantee";
import Search from "../components/Search/Search";
import Repair from "../components/Repair/Repair";

const Home = () => {
    return (
        <div>
            {/*<Header />*/}
            <Main />
            <Delivery />
            <Guarantee />
            <Search />
            <Footer />
            {/*<Repair />*/}
        </div>

    );
};

export default Home;
