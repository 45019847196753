import React from "react";
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Home from "./screens/Home";
import About from "./screens/About";
import './App.css';

function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Home />} />
              {/*<Route path="about" element={<About />} />*/}
          </Routes>
      </BrowserRouter>
  );
}

export default App;
