import React from 'react';
import {Link} from 'react-scroll'
import logo from '../../images/115.png'
import './Header.css'
import {Trans, useTranslation} from "react-i18next";


const lngs = {
    en: { nativeName: 'En' },
    ru: { nativeName: 'Ru' }
}


const Header = () => {
    const { t, i18n } = useTranslation()

    return (
        <div className="logo">

                <div className="logo-box">
                    <div className="logo-img">
                        <img src={logo}/>
                    </div>

                    <div className="logo-section">

                        <div className="logo-text">
                            {/*<a><Link  to="about" spy={true} smooth={true}>о нас</Link></a>*/}
                            <a href="#output" className="lng-header" key="output"><Link  to="delivery" spy={true} smooth={true}><Trans i18nKey="header.part1">
                                Продукция
                            </Trans></Link></a>
                            {/*<a><Link  to="team" spy={true} smooth={true}>команда</Link></a>*/}
                            <a><Link  to="tariffs" spy={true} smooth={true}><Trans i18nKey="header.part2">
                                Отзывы
                            </Trans></Link></a>
                            <a>+996 778 128 698</a>
                            <a>office@altynkur.kg</a>

                        </div>

                        <div className="language-button">
                            {Object.keys(lngs).map((lng) => (
                                <button key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
                                    {lngs[lng].nativeName}

                                </button>
                            ))}
                        </div>
                    </div>

                </div>



        </div> );
};

export default Header;
