import React from 'react';
import './Main.css'
import Header from "../Header/Header";
import logo from "../../images/115.png";
import icons1 from "../../images/industrial-robot.webp"
import icons2 from "../../images/industrial-robot-3.webp"
import icons3 from "../../images/machine-press.webp"
import icons4 from "../../images/tanks.webp"
import icons5 from "../../images/3d-printer.webp"
import icons6 from "../../images/industrial-robot-4.webp"
import icons7 from "../../images/tanks-2.webp"
import icons8 from "../../images/gear.webp"
import Container from "@mui/material/Container";
import { useTranslation} from "react-i18next";

const Main = () => {
    const { t } = useTranslation()
    return (
        <div className="main_bg">

                <Header />
                <div className="main_bg_mask">
                    <Container>
                    {/*<h1>Лучшие условия по поставке импортного оборудования</h1>*/}
                    <h1>{t('main.part1')}</h1>
                    {/*<p>экспорт и импорт промышленного оборудования</p>*/}
                    <h2>{t('main.part2')}</h2>
                    <div className="main_icons">
                        <div className="main_icons_box">
                            <img src={icons1}/>
                            {/*<p>ЛАЗЕРНОЕ И ПЛАЗМЕННОЕ ОБОРУДОВАНИЕ</p>*/}
                            <p>{t('main.part3')}</p>
                        </div>
                        <div className="main_icons_box">
                            <img src={icons2}/>
                            {/*<p>ПРОМЫШЛЕННОЕ ОБОРУДОВАНИЕ</p>*/}
                            <p>{t('main.part4')}</p>
                        </div>
                        <div className="main_icons_box">
                            <img src={icons3}/>
                            {/*<p>ГИДРАВЛИЧЕСКОЕ И ПНЕВМАТИЧЕСКОЕ ОБОРУДОВАНИЕ</p>*/}
                            <p>{t('main.part5')}</p>
                        </div>
                        <div className="main_icons_box">
                            <img src={icons4}/>
                            {/*<p>НАСОСНОЕ ОБОРУДОВАНИЕ</p>*/}
                            <p>{t('main.part6')}</p>

                        </div>
                        <div className="main_icons_box">
                            <img src={icons5}/>
                            {/*<p>ПРОИЗВОДСТВЕННЫЕ ЛИНИИ</p>*/}
                            <p>{t('main.part7')}</p>

                        </div>
                        <div className="main_icons_box">
                            <img src={icons6}/>
                            {/*<p>ЛАБОРАТОРНОЕ ОБОРУДОВАНИЕ</p>*/}
                            <p>{t('main.part8')}</p>

                        </div>
                        <div className="main_icons_box">
                            <img src={icons7}/>
                            {/*<p>ФИЛЬТРОВАЛЬНОЕ ОБОРУДОВАНИЕ</p>*/}
                            <p>{t('main.part9')}</p>

                        </div>
                        <div className="main_icons_box">
                            <img src={icons8}/>
                            {/*<p>КОМПЛЕКТУЮЩИЕ</p>*/}
                            <p>{t('main.part10')}</p>

                        </div>
                    </div>
                    </Container>
                </div>




        </div>
    );
};

export default Main;
