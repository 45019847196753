import React from 'react';
import './Delivery.css'
import photo1 from "../../images/altynkur_equipment1.jpg";
import photo2 from "../../images/altynkur_equipment2.jpg";
import photo3 from "../../images/altynkur_equipment3.jpg";
import photo4 from "../../images/altynkur_equipment4.jpg";
import photo5 from "../../images/altynkur_equipment5.jpg";
import photo6 from "../../images/altynkur_equipment6.jpg";
import photo7 from "../../images/altynkur_equipment7.jpg";
import photo8 from "../../images/altynkur_equipment8.jpg";
import photo9 from "../../images/bucket.jpg";
import photo10 from "../../images/bucket2.jpg";
import photo11 from "../../images/Опорно-поворотные устройства.jpg";
import photo12 from "../../images/Компенсатор резиновый.jpg";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import {useTranslation} from "react-i18next";
// import { styled } from '@mui/material/styles';
//
// const Img = styled('img')({
//     margin: 'auto',
//     display: 'block',
//     maxWidth: '100%',
//     maxHeight: '100%',
// });
//
// const cardStyle = {
//     height: '350px'
// }

const Delivery = () => {
    const { t } = useTranslation()
    return (
        <div className="delivery">
            <div className="delivery_mask">
                <Container>
                    <div className="delivery_text">
                        {/*<h2>Осуществляем поставку промышленного оборудования для любой отрасли промышленности</h2>*/}
                        <h2>{t('delivery.part1')}</h2>
                    </div>
                    <div className="delivery_box">
                        <div className="delivery_block">
                            <img src={photo1}/>
                            {/*<p>Оборудование для дезинфекции</p>*/}
                            <p>{t('delivery.part2')}</p>

                        </div>
                        <div className="delivery_block">
                            <img src={photo2}/>
                            {/*<p>Промышленные станки</p>*/}
                            <p>{t('delivery.part3')}</p>


                        </div>
                        <div className="delivery_block">
                            <img src={photo3}/>
                            {/*<p>фильтровальное оборудование</p>*/}
                            <p>{t('delivery.part4')}</p>

                        </div>
                        <div className="delivery_block">
                            <img src={photo4}/>
                            {/*<p>насосное оборудование</p>*/}
                            <p>{t('delivery.part5')}</p>

                        </div>
                        <div className="delivery_block">
                            <img src={photo5}/>
                            {/*<p>гидравлика</p>*/}
                            <p>{t('delivery.part6')}</p>

                        </div>
                        <div className="delivery_block">
                            <img src={photo6}/>
                            {/*<p>приводная техника</p>*/}
                            <p>{t('delivery.part7')}</p>

                        </div>
                        <div className="delivery_block">
                            <img src={photo7}/>
                            {/*<p>различный кип</p>*/}
                            <p>{t('delivery.part8')}</p>

                        </div>
                        <div className="delivery_block">
                            <img src={photo8}/>
                            {/*<p>электрооборудование</p>*/}
                            <p>{t('delivery.part9')}</p>

                        </div>

                    </div>
                    <div className="delivery_text">
                        {/*<h2>Оборудования для горнодобывающей промышленности</h2>*/}
                        <h2>{t('delivery.part10')}</h2>
                    </div>
                    <div className="delivery_box">

                        <div className="delivery_block">
                            <img src={photo9}/>
                            {/*<p>Погрузочный ковш</p>*/}
                            <p>{t('delivery.part11')}</p>

                        </div>
                        <div className="delivery_block">
                            <img src={photo10}/>
                            {/*<p>Погрузочный ковш</p>*/}
                            <p>{t('delivery.part12')}</p>

                        </div>
                        <div className="delivery_block">
                            <img src={photo11}/>
                            {/*<p>Опорно-поворотные устройства</p>*/}
                            <p>{t('delivery.part13')}</p>

                        </div>
                        <div className="delivery_block">
                            <img src={photo12}/>
                            {/*<p>Компенсатор резиновый</p>*/}
                            <p>{t('delivery.part14')}</p>

                        </div>
                    </div>


                </Container>

            </div>

        </div>
    );
};

export default Delivery;
